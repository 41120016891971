<template>
    <div class="mt-0">
        <!-- 
            When removing this banner, you'll need to make the following edits:

            1. Update show: false in Banner.vue
            2. RibbonBanner.vue: set .bannerWrapper {margin-top: 5rem}
            3. Navigation.vue: set .navbar {top: 0}
        -->
        <banner></banner>
        <navigation></navigation>
        <div class="container-spacing">
            <slot></slot>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import Navigation from '@nextgenmath/js/components/Navigation.vue';
    import Foot from '@nextgenmath/js/components/Foot.vue';
    import Banner from '../components/Banner.vue';

    export default {
        components: {
            Banner,
            Navigation,
            Foot,
        }
    }
</script>

<style lang="scss">

.container-spacing {
    padding-bottom: 1em;

    @media (min-width: 992px) {	
        // margin-top: 8rem; // announcement banner on
        margin-top: 5rem; // announcement banner off
    }
}
</style>